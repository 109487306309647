import { Ref, onMounted, onBeforeUnmount } from 'vue';

declare var bootstrap: any;

export function useTooltip(elementRef: Ref<HTMLElement | null>, htmlContent: Ref<string>, enableHtml: boolean = false) {
    onMounted(() => {
        if (elementRef.value) {
            new bootstrap.Tooltip(elementRef.value, {
                html: enableHtml,
                title: htmlContent.value
            });
        }
    });

    onBeforeUnmount(() => {
        if (elementRef.value) {
            const tooltipInstance = bootstrap.Tooltip.getInstance(elementRef.value);
            if (tooltipInstance) {
                tooltipInstance.dispose();
            }
        }
    });
}
